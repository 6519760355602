<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },

  async created() {
    await this.getAllInfoClr("profile");

    this.colores = this.profiles;
    this.actualizarColores();
  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
  },
};
</script>

<style>
@import "./css/default.css";

@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Sora:wght@100;200;300;400;500;600;700;800&family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Glory:wght@100;200;300;400;500;600;700;800&display=swap');

@font-face {
  font-family: "Tulisans" /*400*/;
  src: url("./assets/fonts/Tulisans_Regular.otf");
  font-display: swap;
}
html {
  scroll-behavior: smooth;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #0055b8;
  --color-2: #f93549;
  --color-3: #ffffff;
  --color-4: #f5f5f5;
  --color-5: #a7a7a7;
  --color-6: #d9d9d9;
  --color-7: #ffffff;
  --color-8: #92c0e9;
  --color-9: #3c3c3c;
  --color-10: #d4f0ff;
  --color-11: #edd8ff;
  --color-12: #fffad0;
  --color-13: #d9ffcc;
  --color-14: #f93549;
  --color-15: #b5ff9b;
  --color-16: #6cb6e0;
  --color-17: #fff496;

  --background: #0055b8;
  --uno: #0055b8;
  --dos: #f93549;
  --tres: #ffc844;
  --opacity: rgba(255, 255, 255, 0.6);

  --Sans: "Source Sans Pro", sans-serif;
  --Open_Sans: "Open Sans", sans-serif;
  --Sora: "Sora", sans-serif;
  --Mukta: "Mukta", sans-serif;
  --dmsans: "DM Sans", sans-serif;
  --Tulisans: "Tulisans", sans-serif;
  --DMSans: "DM Sans", sans-serif;
  --Handlee: "Handlee", cursive;
}


  #EditPln .ql-container.ql-snow, #AddPln .ql-container.ql-snow {
    background-color: #ffffff !important;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
}

body {
  margin: 0vw;
}
.dflxc {
  display: flex;
  justify-content: center;
}
.dflxr{
  display: flex;
  justify-content: right;
}
html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}
p.chpass{
  color: #3c3c3c;
  font-family: 'DM Sans';
  margin: 0vw;
  margin-bottom: .2vw;
  font-size: .8vw;
}
.mB3F4 .ql-toolbar.ql-snow {
  display: none;
}
.mB3F4 .ql-editor {
  resize: none;
  width: 21.858333vw;
  height: 10.241667vw;
  min-block-size: fit-content;
  background-color: #f9f9f9;
  border: none;
  font-size: 0.625vw;
  line-height: 0.8135416666666666vw;
  font-weight: 400;
  font-family: var(--dmsans);
  margin: auto;
  padding: 0.8333333333333334vw 0vw;
}
.mB3F4 .ql-container {
  border: none !important;
}

.dflx {
  display: flex;
}
.dflxa {
  display: flex;
  align-items: center;
}
.dflxca {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dflxsp {
    display: flex;
    justify-content: space-between;
}
.dflxfe {
    display: flex;
    justify-content: flex-end;
}
.mrl05 {
  margin-left: 0.5vw !important;
  margin-top: 0vw !important;
  margin-bottom: 0vw !important;
}
.porel{
  position: relative;
}
.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.box_ctr {
  display: flex;
  justify-content: center;
}
.box_ctr_alg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.crs_ptr {
  cursor: pointer;
}

.active_color {
  background-color: var(--color-1) !important;
  color: #f5f5f5 !important;
}


/* formulario respuesta */

.editor_frm_res{
  width: 41.458vw;
  margin-top: 1.198vw;
  margin-bottom: 1.406vw;
  margin-left: 2.188vw;
}
   .editor_frm_res .ql-toolbar {
    border-radius: 0.521vw 0px 0px 0px  !important;
    }

  .editor_frm_res  .ql-container{
      
    border-radius: 0vw !important;

  }
/* fin formulario respuesta */

.editor_frm_pre{
  width: 37.72vw;
  margin-top: 0.417vw;
  margin-bottom: 1.406vw;
}
.editor_frm_pre .ql-toolbar {
    border-radius: 0.521vw 0px 0px 0px  !important;
    }

  .editor_frm_pre  .ql-container{
      
    border-radius: 0vw !important;

  }

  p.ttl_nv{
    color: #242424;
    font-weight: 700;
    font-size: 0.7291666666666666vw;
    margin-bottom: 0.3vw;
    font-family: 'DM Sans';
    margin-bottom: .5vw;
  }
  .opt_nv{
    display: flex;
    align-items: center;
    margin-right: 1vw;
    padding:0vw .5vw .2vw .5vw;
    cursor: pointer;
    border-bottom: .2vw solid transparent;
    transition: all 300ms;
  }
  .opt_nv:hover{
    border-bottom: .2vw solid #0055b8;
  }
  .hover_disable:hover{
    border-bottom: .2vw solid transparent !important;
  }

  .opt_nv img{
    margin-right: .5vw;
    width: .8vw;
    height: .8vw;
  }
  .opt_nv p{
    margin: 0vw;
    font-family: 'DM Sans';
    color: #3c3c3c;
    font-size: .8vw;
  }
  .opt_nv p.sel{
    
    font-weight: 600;
  }

  .opts_sec{
    width: 97%;
    display: flex;
    justify-content: right;
    margin-bottom: 2vw;
  }

  .opts_sec a{
    margin-left: 1vw;
    background-color: #F93549;
    color: white;
    padding: .5vw 1vw;
    cursor: pointer;
    text-decoration: none;
    font-family: 'DM Sans';
    letter-spacing: .1vw;
    border-radius: 5vw;
  }

  .carg_btns{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .7vw;

   
}
.carg_btns p:first-child{
    margin-right: 1vw;
}
.carg_btns p{
    background-color: #0055B8;
    border-radius: 1.5vw;
   height: 2vw;
    margin: 0vw;
    letter-spacing: 3px;
    font-family: var(--DMSans);
    font-size: .7vw;
    width: 6vw;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}

.carg_btns p img{
    width: 3.5vw;
}
.cargando_pl{
    width: 14vw !important;
    color: white !important;
}

.notas::-webkit-scrollbar {
  width: 0.3vw;
}
.notas::-webkit-scrollbar-thumb {
    background: var(--uno);
    border-radius: 4px;
}

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
.notas::-webkit-scrollbar-thumb:hover {
    background: rgb(2, 2, 146);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Cambiamos el fondo cuando esté en active */
.notas::-webkit-scrollbar-thumb:active {
    background-color: rgb(2, 2, 146);
}


</style>
