import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import aula from './modules/aula'
import colors from './modules/colors'
import copy from './modules/copy'
import grupo from './modules/grupo'
import header from './modules/header'
import logo from './modules/logo'
import maestro from './modules/maestro'
import main from './modules/main'
import mainmtr from './modules/mainmtr'
import planeacion from './modules/planeacion'
import plantilla from './modules/plantilla'
import profile from './modules/profile'
import usuarios from './modules/usuarios'
import archivo from './modules/archivo'
import materialD from './modules/materialD'
import horario from './modules/horario'
import calendario from './modules/calendario'
import asistencia from './modules/asistencia'
import nota from './modules/nota'
import background from './modules/background'
import foro from './modules/foro'
import home_menu from './modules/home_menu'
import evento from './modules/evento'
import pregunta from './modules/pregunta'
import plans from './modules/plans'
import ciclo from './modules/ciclo'
import calificacion from './modules/calificacion'
import suscripcion from './modules/suscripcion'
import plans_copy from './modules/plans_copy'
import type from './modules/type'
import adm_pa from './modules/adm_pa'
import socio from './modules/socio'
import tarea from './modules/tarea'
import tarea_calif from './modules/tarea_calif'
import deposito from './modules/deposito'
import evaluacion from './modules/evaluacion'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    main: main,
    usuarios: usuarios,
    profile:profile,
    colors:colors,
    logo:logo,
    maestro:maestro,
    grupo:grupo,
    mainmtr:mainmtr,
    aula:aula,
    planeacion:planeacion,
    copy:copy,
    plantilla:plantilla,
    archivo:archivo,
    header:header,
    materialD: materialD,
    horario:horario,
    calendario: calendario,
    asistencia: asistencia,
    nota:nota,
    background:background,
    foro:foro,
    home_menu: home_menu,
    evento: evento,
    pregunta:pregunta,
    plans:plans,
    ciclo: ciclo,
    calificacion: calificacion,
    suscripcion:suscripcion,
    plans_copy:plans_copy,
    type:type,
    adm_pa:adm_pa,
    socio: socio,
    tarea: tarea,
    tarea_calif: tarea_calif,
    deposito:deposito,
    evaluacion
  }
})
