"use strict";



const local ='http://localhost:3461'
const cpanel ='https://sipad.mx' // cambiar por la url del sitio web.

exports.url  =  cpanel + '/api/Ncl_q3O/'; 


const data =[
    "Aguascalientes" ,
    "Baja California" ,
    "Baja California Sur" ,
    "Campeche" ,
    "Chiapas" ,
    "Chihuahua" ,
    "Ciudad de México" ,
    "Coahuila" ,
    "Colima ",
    "Durango" ,
    "Estado de México" ,
    "Guanajuato" ,
    "Guerrero" ,
    "Hidalgo" ,
    "Jalisco" ,
    "Michoacán" ,
    "Morelos" ,
    "Nayarit" ,
    "Nuevo León" ,
    "Oaxaca" ,
    "Puebla" ,
    "Querétaro" ,
    "Quintana Roo" ,
    "San Luis Potosí" ,
    "Sinaloa" ,
    "Sonora" ,
    "Tabasco" ,
    "Tamaulipas" ,
    "Tlaxcala" ,
    "Veracruz" ,
    "Yucatán" ,
    "Zacatecas" ,
  ]

  exports.estados  =  data; 